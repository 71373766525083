.textField {
    width: 100%;
    margin-bottom: 10px !important;
}

.buttons {
    margin-top: 10px;
}

.optionLabel {
    height: 200px
}