.textField {
  width: 100%;
  margin-bottom: 10px !important;
}
.textArea {
  width: 100%;
  height: 4em;
  margin-bottom: 10px !important;
  padding: 12px;
}
.buttons {
  margin-top: 10px;
}
.helpButon {
  margin-top: 10px;
}
.warningBox {
  margin: 10px 0 10px 0;
  padding: 10px;
  border: 4px solid #ff4444;
  background-color: #eee;
}
