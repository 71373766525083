.scaffold {
    width: 715px;
    padding-top: 402px;
    position: relative;
}

.editBar {
    position: absolute;
    margin-top: -30px;
    height: 30px;
    right: 0px;
    z-index: 100;
}

.objectContentsEmpty {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    z-index: 99;
    cursor: pointer;
}

.objectContents {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    z-index: 100;
    cursor: pointer;
}

.objectContents iframe {
    width:100% !important;
    height: 100% !important;
}

.editBarButton {
    border-radius: 50%;
    border: 1px solid #000;
    background: #FFF;
    width: 26px;
    height: 26px;
    float: left;
    margin-left: 5px;
    z-index: 100;
}

.objectScaffold {
    z-index:100;
    cursor: pointer;
}

.objectScaffoldEmpty {
    cursor: pointer;
}

.vhelement { 
    position: absolute;
 }

.vhbuttonscene  { background-color: rgba(0,0,0,0) !important; }

.vhbutton { 
    background-image: none !important; 
    background: rgba(84,86,89,0.9) !important; 
    border-radius: 0px 4px 4px 0px !important; 
    padding-left: 5px; 
    padding-right: 20px; 
    font-size: 9px !important; 
    padding-top: 1px;
    padding-bottom: 10px; 
    color:#FFF;
    width:70px;
    height:15px;
    overflow-wrap:unset;
    cursor: pointer;
}

.vhbutton:after {
    content: ""; position: absolute;
    top: 0px; left: -14px; width: 15px; height:15px; bottom: 0px;;
    background: rgba(114,117,119,0.9);
    border-radius: 4px 0px 0px 4px;
    background-repeat: no-repeat; background-size: 24px 24px; background-position: center center;
}

.vhbuttonscene:after { background-image: url('btnscenetail.png'); }

.buttonText
{
    width:65px;
    white-space: nowrap;
    overflow: hidden;
}