.textField {
  width: 100%;
  margin-bottom: 10px !important;
}
.textFieldHalf {
  width: 50%;
  margin-bottom: 10px !important;
}
.checkbox {
  margin-bottom: 10px !important;
}
.buttons {
  margin-top: 10px;
}

.formWrapper {
  display: flex;
  justify-content: space-between;
}

.program {
  display: grid;
  max-height: 450px;
  grid-template-rows: repeat(6, 54px);
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid #121c42;
  max-width: 335px;
  width: 100%;
  border-radius: 6px;
  padding: 60px 30px 10px;
  grid-gap: 6px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media screen and (max-width: 990px) {
  .formWrapper {
    flex-flow: column nowrap;
    align-items: center;
  }

  .program {
    position: unset;
    margin: 40px 0 60px;
  }
}

.date {
  display: block;
  position: relative;
  font-size: 44px;
  font-weight: 600;
  grid-area: 1;
}

.date::before {
  content: '';
  background: url('../../../img/program.png') no-repeat center center;
  padding: 12px;
  background-size: 24px;
  position: absolute;
  top: -26px;
  left: 0;
}

.month {
  vertical-align: super;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
}

.time {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  grid-area: 1 / 2 / 1 / 2;
  text-align: right;
  flex-flow: column nowrap;
  justify-content: end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scene {
  font-size: 16px;
  font-weight: 300;
  padding-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  grid-area: 2 / 1 / 2 / 3;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 0 0 0;
  margin: 0;
}

.speaker {
  grid-area: 3 / 1 / 3 / 3;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
}
.description {
  grid-area: 4 / 1 / 4 / 3;
  display: block;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 44px;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.divider {
  grid-area: 6 / 1 / 6 / 3;
  display: flex;
  border-top: 0.5px solid #121c42;
  width: 100%;
  padding-top: 20px;
  flex-flow: row nowrap;
}
.attendBtn {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  padding: 12px 20px;
  margin-right: auto;
  border: 1px solid #121c42;
  background: #121c42;
  color: #fff;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background 400ms ease, color 400ms ease;
}
.attendBtn:hover {
  background: #fff;
  color: #121c42;
}
.registerBtn {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  padding: 10px 20px;
  margin-left: auto;
  border: 1px solid #121c42;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background 400ms ease, color 400ms ease;
}
.registerBtn:hover {
  background: #121c42;
  color: #fff;
}
.speakerInfo {
  grid-area: 5 / 1 / 5 / 2;
  text-decoration: underline;
  position: relative;
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}
.speakerInfo::after {
  content: '';
  position: relative;
  right: -8px;
  top: 2px;
  padding: 10px;
  background: url('../../../img/right-arrow.png') no-repeat center center;
  background-size: 16px;
  transition: right 0.4s;
}
.speakerInfo:hover::after {
  right: -12px;
  transition: right 0.4s;
}

.registerBtn {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  padding: 12px 20px;
  margin-left: auto;
  border: 1px solid #121c42;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background 400ms ease, color 400ms ease;
}
.registerBtn:hover {
  background: #121c42;
  color: #fff;
}

.singleDate {
  display: block;
  position: relative;
  font-size: 44px;
  font-weight: 600;
  grid-area: 1;
}

.month {
  font-size: 14px;
}

.twoDates {
  display: flex;
  position: relative;
  font-size: 30px;
  font-weight: 600;
  grid-area: 1;
  justify-content: space-between;
  align-items: flex-end;
}

.singleDate .month {
  font-size: 18px;
}

.singleDate::before,
.twoDates::before {
  content: '';
  background: url('../../../img/program.png') no-repeat center center;
  padding: 12px;
  background-size: 24px;
  position: absolute;
  top: -26px;
  left: 0;
}

.year {
  position: absolute;
  top: -19px;
  left: 28px;
  font-weight: 400;
  font-size: 0.8rem;
}

.endDate::before {
  position: absolute;
  content: '-';
  padding: 10px;
  font-size: 10px;
  font-weight: 600;
  left: 10px;
  color: #121c42;
}
