.textField {
    width: 100%;
    margin-bottom: 10px !important;
}
.textFieldHalf{
    width: 50%;
    margin-bottom: 10px !important;
}
.checkbox {
    margin-bottom: 10px !important;
}
.buttons {
    margin-top:10px;
}