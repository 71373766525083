.formWrapper {
  display: flex;
  justify-content: space-between;
}

.formWrapper form {
  width: 500px;
  max-width: 500px;
  min-width: 500px;
}

.gallery-card-wrapper {
  display: flex;
  flex-flow: column nowrap;
  margin: 16px 50px 0;
}

.gallery-card {
  display: flex;
  flex-flow: column nowrap;
  background: #fff;
  color: #121c42;
  border-radius: 6px;
  padding: 20px 0;
  border: 1px solid #121c42;
  height: 500px;
  width: 100%;
  min-width: 340px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: opacity 400ms ease;
  opacity: 1;
}

.gallery-card .gallery-card-image {
  height: 210px;
  display: flex;
  align-self: center;
}

.gallery-card .gallery-card-image img {
  display: block;
  width: 250px;
  height: 210px;
  border-radius: 6px;
  object-fit: cover;
}

.gallery-card .gallery-card-text-wrapper {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  padding: 0 40px;
}

.card-disabled {
  opacity: 0.4;
  transition: opacity 400ms ease;
}

.gallery-card .gallery-card-title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 24px 0 0 0;
  margin-top: 15px;
  border-top: 1px solid #121c42;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gallery-card .gallery-card-subtitle {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gallery-card .gallery-card-description {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  padding: 20px 0 0 0;
  @supports (-webkit-line-clamp: 3) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.gallery-card .gallery-card-cta {
  margin: 20px 40px 0 0;
  font-size: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #121c42;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  width: fit-content;
  max-width: 220px;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 6px;
  transition: background-color 400ms ease, color 400ms ease,
    border-color 400ms ease;
}

.gallery-card .gallery-card-cta:hover {
  background-color: #fff;
  color: #121c42;
  border-color: #121c42;
}

.card-disabled-banner {
  border-radius: 6px;
  padding: 10px 14px;
  background-color: #fff;
  color: #fff;
  opacity: 0;
  margin-top: 20px;
}

.card-disabled-banner.active {
  background-color: #ea2429;
  color: #fff;
  opacity: 1;
  transition: opacity 1s ease, background-color 1s ease, color 1s ease;
}
