.text-header{
    padding: 0px 12px
}

.activeUsersContainer:after {
    clear: both;
}

.activeUserHolder {
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid black;
}
