.sortField {
    width:100%;
    margin: 5px 10px 5px 0;
    border:1px solid #CCC;
    padding: 10px;
    height:45px;
    cursor: default !important;
}
.sortField:after {
    clear:both;
}

.sortField .leftButtons {
    float:left;
    padding:0px;
    margin: 0px;
    cursor: move;
}

.sortField .title {
    float:left;
    padding:0px;
    margin: 2px 0 0 0;
    cursor: default;
}
.sortField .title:after {
    clear:both;
}

.sortField .buttons {
    float:right;
    padding:0px;
    margin: 0px;
    cursor: pointer;
}
.sortField .buttons:after {
    clear:both;
}

.addFieldButton {
    padding:10px;
}