div.gallery {
    border: 1px solid #ccc;
  }
  
  div.gallery:hover {
    border: 1px solid #777;
  }
  
  div.gallery img {
    width: 100%;
    height: auto;
  }
  
  div.gallery video {
    width: 100%;
    height: auto;
  }
  
  div.desc {
    padding: 5px 0 0 10px;
    height:70px;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .responsive {
    padding: 0 6px;
    float: left;
    width: 24.99999%;
  }
  
  @media only screen and (max-width: 700px) {
    .responsive {
      width: 49.99999%;
      margin: 6px 0;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .responsive {
      width: 100%;
    }
  }
  
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
    height:10px;
  }