/* layouts */
.layout{
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.layout__long{
  flex: 0 0 526px;
}

.layout__short{
  flex: 0 0 200px;
}

.boxContainer{
  display: flex;
  flex-wrap: wrap;
}

/* filters */
.filter{
  display: flex;
}

.filter > div{
  flex: 1 1 auto;
  padding: 0 10px 10px 10px;
}

.filter > div:nth-child(1){
  padding-right: 0px;
  padding-left: 0px;
}

.filter button{
  flex: 0 0 148px;
  position: relative;
  background: #2196f3;
  height: 39px;
  color: #fff;
}

/* box */
.box{
  flex: 0 0 140px;
  height: 100px;
  border: 2px solid #5d7eed;
  border-radius: 12px;
  margin: 15px;
  padding: 0px 5px;
  position: relative;
}

.box__icon{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 45px;
  left: -24px;
}

.box__icon img{
  width: 100%;
}

.title {
  margin: 80px 0 40px 0;
  color: #121C42;
}

.box__title{
  position: relative;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  z-index: 2;
}

.box__value{
  text-align: center;
  font-size: 32px;
  margin-top: -5px;
}

.box__movment{
  position: absolute;
  font-size: 12px;
  bottom: 5px;
  text-align: center;
  width: 100%;
  color: #9e9e9e;
}

.box__movment img{
  width: 25px;
  vertical-align: middle;
  margin-left: -8px;
  margin-right: -8px;
}

/* box tall */
.boxTall{
  height: calc(100% - 25px);
  border: 2px solid #5d7eed;
  border-radius: 12px;
  margin: 15px;
  padding: 0px 5px;
  position: relative;
}

.boxTall__icon{
  position: absolute;
  top: 50%;
  left: -26px;
  transform: translateY(-50%);
  width: 50px;
}

.boxTall__icon img{
  width: 100%;
}

.boxTall__title{
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
}

.boxTall__lines{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.line{
  font-size: 14px;
}

.line--divider{
  border-top: 1px solid #5d7eed;
  margin: 20px auto;
  max-width: 60%;

}

.line__label{
  display: inline-block;
  text-align: right;
  width: 80px;
  margin-right: 8px;
}

.line__value{
  display: inline-block;
  font-weight: bold;
}

.boxTall__movment{
  position: absolute;
  font-size: 12px;
  bottom: 5px;
  text-align: center;
  width: 100%;
  color: #9e9e9e;
}

.boxTall__movment img{
  width: 25px;
  vertical-align: middle;
  margin-left: -8px;
  margin-right: -8px;
}
