.quote-slider {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    max-height: 200px;
    height: 100%;
    position: relative;
}

.quote {
    font-weight: 600;
    font-size: 1rem;
    color: #fff;
    margin-top: 40px;
    text-align: left;
  }
  
  .cite {
    font-weight: 400;
    font-size: 0.8rem;
    color: #fff;
    margin-top: 10px;
    text-align: left;
  }

  
  
  
  