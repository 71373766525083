::-webkit-scrollbar {
  width: 5px;
}

#root {
  overflow-x: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 10px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  width: 100vw;
  height: 100vh;
  background: url('./img/hero.png') no-repeat center center fixed;
  background-size: cover;
  padding-top: 80px;
}

h1 {
  margin-top: 0px;
}

h2 {
  margin-top: 0px;
}

h3 {
  margin-top: 0px;
}
