.chatOverviewScaffold {
  margin: 5px 0 5px 0;
  height: 100%;
}

.floatRight {
  float: right;
  clear: both;
}

.chatUpperContainer {
  height: 130px;
}

.chatLowerContainer {
  border-top: 1px solid #ddd;
}

.middleItem {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  height: 76vh;
}

.middleItem.title {
  font-size: 26px;
  font-weight: 600;
}

.chatNetworksOverview {
  padding: 0 !important;
  height: 100%;
  overflow-y: auto;
}

.rightItem {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.bottomItem {
  position: absolute;
  bottom: 0;
  padding: 20px;
}

.sendMessage {
  height: 55px;
  margin-left: 20px !important;
  background: #121c42 !important;
}

.message {
  border-radius: 12px;
  background: #121c42;
  color: #fff;
  margin-bottom: 12px;
  padding: 10px;
  width: fit-content;
}

.chatRoom {
  border: 1px solid #121c42;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  margin: 20px auto;
  padding: 10px;
  width: 80%;
  transform: translate(0px);
  transition: transform 300ms;
}

.chatRoom:hover {
  transform: translateY(-5px);
}

.newMessageNotification {
  position: relative;
}

.newMessageNotification::after {
  content: '';
  position: absolute;
  background-color: #ffbb36;
  padding: 6px;
  border: 5px solid #fff;
  width: 10px;
  height: 10px;
  right: -7px;
  top: -7px;
  border-radius: 50%;
}
