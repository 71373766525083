.userCardScaffold {
    display: flex;
    height: auto;
}

.userProfileContainer {
    display: flex;
    align-items: center;
    gap: 10px
}

.chatButton{}

.floatRight {
    float: right;
    clear: both;
}

.userProfileName{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    font-size: 14px;
}

.linkedInButton {
    width: 20px;
}