.statusBox {
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    text-align: center;
    color:white;
}
.ended {
    background-color: grey;
}
.planned {
    background-color: #f0ad4e;
}
.aboutToStart {
    background-color: #f0ad4e;
}
.active {
    background-color: #5cb85c;
}
.endingSoon {
    background-color: #d9534f;
}
.scheduling {
    background-color: #ddd;
    color: #000;
}

.buttonRow {
    margin-top: 5px;
    margin-bottom: 5px;
}