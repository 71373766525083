.templateMenuBackground {
  background: url('../../../img/lobby-snippet.png') no-repeat center center;
  background-size: auto;
  border-radius: 6px;
  height: 100px;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.templateMenuWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 90%;
  padding: 20px;
  height: 50px;
  border-radius: 6px;
}

.templateMenuItem {
  font-size: 1.2rem;
}

.templateMenuItem::after {
  content: '|';
  margin: 0 8px;
}

.templateMenuItem:last-child::after {
  content: '';
  margin: 0;
}
