@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,200;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif, Verdana;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #fff, #edf5fe);
}

code {
  font-family: 'Inter', sans-serif, Verdana;
}
